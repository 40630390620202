import React, { useEffect } from "react";
import homeimg from "../../../../assets/taha-assets/1.png";
import homeimg2 from "../../../../assets/taha-assets/5.png";
import "../header/header.css";
import AOS from "aos";
import $ from "jquery";
import "aos/dist/aos.css";
export const Header = () => {
  AOS.init({
    offset: 200,
    duration: 600,
    easing: "ease-in-sine",
    delay: 100,
    once: false,
    mirror: true,
  });
  useEffect(()=>{
    if(window.scrollY<1){
      $(".scroll").css('display','none');
    }
    window.addEventListener("scroll",function(){
      if(window.scrollY<1){
        $(".scroll").css('display','none');
        $(".scroll").css('display','none');
      }
      else{
         $(".scroll").css('display','block');
        }
  })
},[])
  const scroll = ()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // أو 'auto' للتمرير الفوري
    });
      }
  return (
    <section className="home-content">
      <div className="scroll">
        <button onClick={scroll} className="button">
          <svg className="svgIcon" viewBox="0 0 384 512">
            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
          </svg>
        </button>
      </div>
      <div className="letters">
        <p className="t">ا</p>
        <p className="h">ل</p>
        <p className="s">ه</p>
        <p className="l">د</p>
        <p className="a">ا</p>
        <p className="ha">د</p>
      </div>
      <section className="home">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="home-left"
        >
          <img className="img1" srcSet={homeimg} alt="" />
         
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="home-right"
        >
          <img className="hadad" src="" alt="" srcSet={homeimg2} />
          <div className="text">
          <h1>أستاذ</h1>
          <h2>محمد الحداد </h2>
          <p className="arabic">مدرس اللغة العربية للثانوية العامة </p>
          </div>
        </div>
      </section>
    </section>
  );
};
