import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../properties/properties.css";
import { FaQuestionCircle } from "react-icons/fa";
import { MdSummarize } from "react-icons/md";
import { MdScreenShare } from "react-icons/md";
import { MdQuiz } from "react-icons/md";
export const Properties = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplayspeed: 500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="prop">
      <h1 className="title">أهم ما يميزنا !</h1>
      <span className="mark1"></span>
      <span className="mark2"></span>
      <span></span>
      <div className="props">
        <div data-aos="fade-left" data-aos-duration="1000" className="proo">
          <MdScreenShare className="ic" />
          <h2>فيديوهات</h2>
          <p>يمكنك مشاهدة الفيديوهات الخاصة بكل درس العديد من مرات المشاهدة</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className="proo">
          <MdSummarize  className="ic" />
          <h2>ملخصات</h2>
          <p>قراءة الملخصات بعد كل درس يتيح لك تثبيت المعلومات وفهمها</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className="proo">
          <FaQuestionCircle  className="ic" />
          <h2>أسئلة</h2>
          <p>
            لك العديد من الأسئلة بعد كل درس يمكنك حل الأسئلة ومعرفة مستواك
          </p>
        </div>
        <div data-aos="fade-right" data-aos-duration="1000" className="proo">
          <MdQuiz  className="ic" />
          <h2>اختبارات</h2>
          <p>عمل اختبارات اسبوعية وشهرية شاملة جميع انواع الأسئلة والأفكار</p>
        </div>
      </div>
    </div>
  );
};
