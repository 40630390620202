import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { auth } from '../../../Context/AuthStore';
import config from '../../../config';
import axios from 'axios';

export default function QuizRow({ quiz, deleteQuiz , refresh}) {

    const {userAuth} = useContext(auth)

    const [openUpdate, setopenUpdate] = useState(false)
    const [updatedData, setupdatedData] = useState({is_mandatory : quiz.is_mandatory})

    const addUpdateData = (e) => {
        let data =  {...updatedData};
        data[e.target.name] = e.target.value;
        setupdatedData(data)
    }

    const updateCheck = () => {
        let check = {...updatedData}
        check.is_mandatory ? check.is_mandatory = false : check.is_mandatory = true;
        setupdatedData(check)
    }

    const toggleUpdate = () => {
        openUpdate ? setopenUpdate(false) : setopenUpdate(true)
    }

    const sendUpdate = (id) => {
        if(Object.keys(updatedData).length == 0){
            Swal.fire({
                text: ' لا يوجد اي تعديل  ' ,
                icon: 'warning'
            })
            return
        }

        axios.put(`${config.basURL}dashboard/quiz-update/${id}` ,updatedData , {
            headers: {
                'Authorization': config.ApiKey ,
                'auth': `Bearer ${userAuth}`
            }
        } ).then(res => {
            Swal.fire({
                text: ' تم التعديل بنجاح ' , 
                icon: 'success'
            })
            refresh()
        }).catch(err => {
            console.log(err)
        })

    }


    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    return <>
        <tr>
            <td> {quiz.name} </td>
            <td> {quiz.quiz_degree} </td>
            <td> {quiz.success_rate} </td>
            <td> {quiz.quiz_questions_count} </td>
            <td> {quiz.time} دقيقه </td>
            <td> {getHumanDate(quiz.created)} </td>
            <td> {quiz.is_mandatory ? 'اجباري' : 'اختياري'} </td>
            <td className='controles'>
                <Link to={`/dashboard/questions-answers/${quiz.id}/quiz`} className='btn'> الاسئله </Link>
                <Link to={`/dashboard/quiz-results/${quiz.id}`} className='btn'> نتائج الطلاب </Link>
                <button className='delete' onClick={() => deleteQuiz(quiz.id)}>
                    حذف
                </button>
                <button className='update' onClick={toggleUpdate}>
                    تعديل
                </button>
            </td>
        </tr>

        <tr className={`update-quiz ${openUpdate ? 'open' : ''}`}>
            <td colSpan={3}>
                <input type="text" className="" name='name' placeholder='اسم الكويز' onChange={addUpdateData} />
            </td>
            <td>
                <input type="text" className="" name='success_rate' placeholder='درجه النجاح' onChange={addUpdateData}/>
            </td>
            <td>
                <input type="text" className="" name='time' placeholder='مده الكويز' onChange={addUpdateData}/>
            </td>
            <td className='checkbox'>
                <input type="checkbox" name="is_mandatory" id={`update-mandatory-${quiz.id}`} className='ms-2' onChange={updateCheck} checked={updatedData.is_mandatory}/>
                <label htmlFor={`update-mandatory-${quiz.id}`}> اجباري </label>
            </td>
            <td colSpan={2}>
                <button onClick={() => sendUpdate(quiz.id)} >
                    تعديل
                </button>
            </td>
        </tr>
    </>
}
