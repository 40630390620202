import React, { useContext, useEffect, useState } from "react";
import dashboardExamImage from "../../../assets/dashboard-exams.svg";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function ExamUpdate() {
  const { exam_id, exam_name, type, course_id } = useParams();
  const { userAuth } = useContext(auth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [examData, setexamData] = useState(false);

  const [updatedData, setupdatedData] = useState({});

  const updateExamData = (e) => {
    let data = { ...updatedData };
    data[e.target.name] = e.target.value;
    if (data[e.target.name] === "" || null) {
      delete data[e.target.name];
    }
    setupdatedData(data);
  };

  const sendUpdateData = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let data = { ...updatedData };

    if (Object.keys(data).length === 0) {
      setIsLoading(false);
      Swal.fire({
        text: "لا يوجد اي تعديل",
        icon: "warning",
      });
      return;
    }

    axios
      .put(`${config.basURL}dashboard/exam-update/${exam_id}`, data, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        getExamData();
        Swal.fire({
          text: "تم التعديل بنجاح",
          icon: "success",
        }).then(() => {
          navigate(`/dashboard/exams/${course_id}/${type}`);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getExamData = () => {
    axios
      .get(`${config.basURL}dashboard/${type}-exam-overview/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        console.log(res.data)
        let exam = res.data.exam_course.filter(
          (e) => e.id === Number(exam_id)
        )[0];
        setexamData({
          ...exam
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getExamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-exams-page update-exam">
        <div className="section-header">
          <h1>تعديل الامتحان : {exam_name}</h1>
          <button
            onClick={() => navigate(`/dashboard/exams/${course_id}/${type}`)}
          >
            العوده
          </button>
        </div>
        <form className="new-exam" onSubmit={sendUpdateData}>
          <div className="inputs">
            <div className="top">
              <select name="is_many_times" id="" onChange={updateExamData}>
                <option>اماكنبه الدخول اكثر من مره</option>
                {examData && (
                  <>
                    <option
                      value={true}
                      selected={examData.is_many_times ? true : false}
                    >
                      {" "}
                      نعم{" "}
                    </option>
                    <option
                      value={false}
                      selected={examData.is_many_times ? false : true}
                    >
                      {" "}
                      لا{" "}
                    </option>
                  </>
                )}
              </select>
              {examData && (
                <input
                  type="text"
                  name="name"
                  placeholder={examData.name}
                  onChange={updateExamData}
                />
              )}
            </div>
            <div className="middle">
              {examData && (
                <>
                  <input
                    type="text"
                    name="display_questions"
                    placeholder={examData.exam_questions_count}
                    onChange={updateExamData}
                  />
                  <input
                    type="text"
                    name="exam_minutes"
                    placeholder={examData.exam_minutes}
                    onChange={updateExamData}
                  />
                </>
              )}
            </div>
            <div className="middle">
              <select
                name="pending"
                className="w-100"
                onChange={updateExamData}
              >
                <option>حاله الامتحان</option>
                <option
                  value={false}
                  selected={!examData.pending  ? true : false}
                >
                  مفعل
                </option>
                <option
                  value={true}
                  selected={examData.pending ? true : false}
                >
                  غير مفعل
                </option>
              </select>
            </div>
            <div className="middle">
              <label htmlFor="result_date"> وقت ظهور النتيجه </label>
              {examData && (
                <input
                  type="date"
                  name="result_date"
                  id="result_date"
                  value={examData.result_date}
                  onChange={updateExamData}
                />
              )}
            </div>
            <div className="bottom">
              {examData && (
                <textarea
                  className="description"
                  name="description"
                  id=""
                  placeholder={examData.description}
                  onChange={updateExamData}
                ></textarea>
              )}
            </div>
            <button className="save-data">
              {isLoading ? (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              ) : (
                "تعديل البيانات"
              )}
            </button>
          </div>
          <div className="image">
            <img src={dashboardExamImage} alt="" className="w-100 h-100" />
          </div>
        </form>
      </div>
    </>
  );
}
