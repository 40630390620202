import React, { useContext, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import config from '../../config'
import axios from 'axios'
import { auth } from '../../Context/AuthStore'

export default function CourseBox({ data }) {

    const { userAuth } = useContext(auth);
    const { showSwal } = useOutletContext();

    const [lessonsCount, setlessonsCount] = useState(0)

    const subscripe = () => {

        if (userAuth === null) {
            showSwal('لا يمكن الاشتراك في الكورس بدون تسجيل الدخول', 'error', '/auth')
        } else {
            axios.post(`${config.basURL}subscription/register-course`, {
                course: data.id
            }, {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`,
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data.created) {
                    res.data.data.course.free ?
                        showSwal('تم الاشتراك في الكورس بنجاح', 'success', '/user-profile/my-courses') :
                        showSwal('تم الاشتراك في الكورس بنجاح', 'success', '/user-profile/course-subscreptions')
                } else {
                    showSwal('لقد قمت بالاشتراك في هذا الكورس من قبل', 'info')
                }

            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        setlessonsCount(0)
        data.course_units.map(unit => setlessonsCount((prev) => prev += unit.unit_lessons.length))
        // console.log(data)
    }, [])

    return <>

        <div className='course-box'>
            <div className='course-image'>
                <img src={`${data.cover}`} className='w-100 h-100' loading='lazy'/>
                {/* <div className='add-course-icon'>
                    <i className="fa-solid fa-plus"></i>
                </div> */}
            </div>
            <div className='lessons-count'>
                <p><i className="ms-1 fa-solid fa-book"></i> { lessonsCount } الدروس</p>
                <p><i className="ms-1 fa-solid fa-share-nodes"></i> { data.course_units.length } الوحدات</p>
                <p><i className="ms-1 fa-regular fa-file"></i> { data.course_file.length } الفايلات</p>
            </div>
            <div className='course-title'>
                <h5 className='title fw-bold'>
                    {data.name}
                </h5>
                {/* <p className='p-0 m-0'>
                    {data.about}
                </p> */}
            </div>
            <div className='course-time-price'>
                <div className='course-time'>
                    <div className='time'>
                        <i className="fa-regular fa-clock"></i>
                        <p className='m-0 p-0'>
                            {
                                getHumanDate(data.created)
                            }
                        </p>
                    </div>
                </div>
                <div className='price'>
                    {
                        data.free ? 'مجاني' : <><span>{data.price}</span>جـ</>
                    }
                </div>
            </div>
            <div className='go-to-course'>
                <Link to={`/course-preview/${data.id}`} className='enter'>
                    الدخول للكورس
                </Link>
                <button onClick={subscripe} className='enroll'>
                    أشترك الأن
                </button>
            </div>
        </div>
    </>
}
