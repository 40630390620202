import React, { useEffect, useState } from 'react'
import studentImage from '../assets/courses/student.svg'
import searchIcon from '../assets/courses/search-icon.svg'
import CoursesSection from './../Components/Courses/CoursesSection';
import axios from 'axios';
import config from '../config';
import useQueryParams from '../Hooks/useQueryParams';

export default function Courses() {

    const {year} = useQueryParams();
    const [allCourses, setAllCourses] = useState([]);
    const [pageTitle, setPageTitle] = useState('جميع السنين');
    const [response, setResponse] = useState(false)

    const getAllCourses = () => {
        axios.get(`${config.basURL}course/course-list`, {
            headers: {
                'Authorization': config.ApiKey,
            }
        }).then((res) => {
            let coursesData = [...allCourses];
            setResponse(true)
            if(Number(year) > 3){
                coursesData = res.data.courses.filter(course => course.year.id > 3 );
                setPageTitle(' المرحله الاعداديه ')
            }else {
                coursesData = res.data.courses.filter(course => course.year.id <= 3 );
                setPageTitle(' المرحله الثانويه ')
            }

            setAllCourses(coursesData)
        }).catch((err) => console.log(err));

    }

    useEffect(() => {
        getAllCourses();
    }, [])

    return <>
        <div className='courses-title'>
            <div className='content'>
                <h5>
                    كورسات {pageTitle}
                </h5>
                <div className='image'>
                    <img className='w-100 h-100' src={studentImage} alt='' />
                </div>
            </div>
        </div>
        <div className='courses-search-bar py-5 mb-4'>
            <div className='container'>
                {/* <div className='search-box'>
                    <input type='search' name='search' placeholder='أبحث بأسم الكورس او المادة' />
                    <div className='search-btn' role='button'>
                        <img src={searchIcon} alt='' className='' />
                    </div>
                </div> */}
            </div>
        </div>
        <CoursesSection data={allCourses} response={response}/>
    </>
}
