import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import CourseUniteBox from '../Components/UserCourses/CourseUniteBox';
import axios from 'axios';
import config from '../config';
import { auth } from "../Context/AuthStore"
import Swal from 'sweetalert2';
import Videocipher from '../Components/Used/Videocipher';
import Video from '../Components/Used/Video';
import Placeholder from "../Components/Used/Placeholder"
export default function WatchLessonByCode() {

    const { code } = useParams()
    const { userAuth } = useContext(auth)
    const [videoData, setvideoData] = useState(false)
    const [lessonData, setlessonData] = useState(false)
    const [exams, setExams] = useState(false)
    const [firstTime, setFirstTime] = useState(true)
    const [avilabile, setAvilabile] = useState(true)
    const [watchTime, setWatchTime] = useState(0)
    const [intervalID, setIntervalID] = useState(null)
    const [lessonNumber, setLessonNumber] = useState(0)
    const navigate = useNavigate()

    const getLessonData = () => {
        axios.get(`${config.basURL}codes/view-lesson-by-code/${code}`, {
            headers: {
                'Authorization': config.ApiKey,
            }
        }).then((res) => {
            // console.log(res.data)
            if (res.data.view_counter === 0 && res.data.view_counter != undefined) {
                Swal.fire({
                    text: 'انتهت مشاهداتك للدرس باستخدام هذا الكود',
                    icon: 'error',
                    willClose: () => navigate('/lesson-by-code')
                })
            }
            setvideoData(res.data.credentials);
            setlessonData(res.data.lesson.lesson)
            setExams(res.data.lesson.lesson.exam_set)
        }).catch((err) => console.log(err))
    }

    const sendWatchTry = () => {
        axios.post(`${config.basURL}codes/view-lesson-by-code/${code}`, {
            watch_time: 0,
            lesson: lessonData.id
        },
            {
                headers: {
                    'Authorization': config.ApiKey,
                }
            }).then((res) => {
                setFirstTime(false);
            }).catch((err) => {
                console.log(err)
                setAvilabile(false)
                clearInterval(intervalID)
            })
    }

    const sendWatchTime = () => {
        axios.put(`${config.basURL}subscription/lesson-views`, {
            watch_time: watchTime / 60,
            lesson: lessonData.id
        },
            {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`
                }
            }).then((res) => { }).catch((err) => console.log(err))
    }

    useEffect(() => {
        getLessonData()
    }, [])

    // send watch time when user close tap or browser
    useEffect(() => {

        const onBeforeUnload = (ev) => {
            sendWatchTime();
            return null;
        };

        window.addEventListener("beforeunload", onBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [watchTime])

    return <>
        <div className='container my-course-watch-page'>

            {
                lessonData && (lessonData.cloud_server !== null ?
                    <Video
                        videoLink={lessonData.cloud_server}
                        firstTime={firstTime}
                        sendWatchTry={sendWatchTry}
                        setIntervalID={setIntervalID}
                        setWatchTime={setWatchTime}
                        intervalID={intervalID}
                        sendWatchTime={sendWatchTime}
                        avilabile={avilabile}
                        watchTime={watchTime}
                    />
                    :
                    <Videocipher
                        avilabile={avilabile}
                        videoData={videoData}
                        firstTime={firstTime}
                        watchTime={watchTime}
                        intervalID={intervalID}
                        sendWatchTime={sendWatchTime}
                        setWatchTime={setWatchTime}
                        setIntervalID={setIntervalID}
                        sendWatchTry={sendWatchTry} />)
            }

            <div className='lesson-info'>
                <div className='lesson-title'>
                    <h5>
                        {
                            lessonData && lessonData.name
                        }
                    </h5>
                </div>
                <div className='lesson-description'>
                    <p className='description'>
                        {
                            lessonData && lessonData.description
                        }
                    </p>
                    <div className='video-duration'>

                    </div>
                </div>
            </div>
            {/* to lesson quiz section */}

            {/* {
                exams && exams.map(exam => <div className='quiz-section'>
                    <div className='start-quiz'>
                        <h6>
                            {exam.name}
                        </h6>
                        <p>
                            {exam.description}
                        </p>
                        <Link to={`/quiz/${course_id}/${exam.id}`}>
                            أبداء
                        </Link>
                    </div>
                </div>)
            } */}

            <div className='course-files mb-5 py-3'>
                {
                    lessonData ? (lessonData.lesson_file.length > 0 ? lessonData.lesson_file.map(file => <a href={file.file} className='file d-block' target='_blank'>
                        <div className="icon">
                            <i className="fa-solid fa-file"></i>
                        </div>
                        <p className='m-0'>
                            {
                                decodeURIComponent(file.file.split('/')[file.file.split('/').length - 1])
                            }
                        </p>
                    </a>) : <p className='w-100 m-0 py-2 text-center'> لا يوجد فايلات في هذا الدرس </p>) : <Placeholder />
                }
            </div>
        </div>
    </>
}
