import axios from 'axios'
import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import config from "../../../config"
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'
import Validator from "../../../Validator"
import LessonViewsRow from '../../../Components/Dashboard/LessonViewsRow/LessonViewsRow'
import CSVMake from '../../../CSVMake'

export default function LessonViews() {

    const { userAuth } = useContext(auth)
    const [lessonViews, setLessonViews] = useState(false)
    const [searchBy, setsearchBy] = useState('all')
    const [searchValue, setsearchValue] = useState({})
    const [nextPage, setNextPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchResults, setsearchResults] = useState(false)
    const [viewsCount, setviewsCount] = useState(0)
    const [preparing, setpreparing] = useState(false)
    const [courseList, setcourseList] = useState(false)

    const [selectedCourse, setselectedCourse] = useState({
        course: null
    })

    const updateSelectedCourse = (e) => {
        let data = { ...selectedCourse };
        data[e.target.name] = e.target.value;
        setselectedCourse(data)
    }

    const updateSearchBy = (e) => {
        let data = searchBy;

        if (e.target.value === 'all') {
            getAllViews()
        } else {
            data = e.target.value;
        }

        setsearchBy(data)
    }

    const updateSearchValue = (e) => {
        let data = { ...searchValue }
        data[e.target.name] = e.target.value;
        setsearchValue(data)
    }

    const search = (e) => {
        e && e.preventDefault();
        setLessonViews(false)

        if (Object.keys(searchValue).length === 0) {
            Swal.fire({
                text: 'برجاء ادخال قيمه للبحث',
                icon: 'warning'
            })
            return;
        }
        axios.get(`${config.basURL}dashboard/lesson-views?${searchBy}=${searchValue[searchBy]}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setviewsCount(res.data.count)
            setsearchResults(true)
            setLessonViews(res.data.results)
            res.data.next ? setNextPage(true) : setNextPage(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const getAllViews = () => {
        setLessonViews(false)
        axios.get(`${config.basURL}dashboard/lesson-views?page=${currentPage}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setviewsCount(res.data.count)
            setLessonViews(res.data.results)
            res.data.next ? setNextPage(true) : setNextPage(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            student: ['name', 'اسم الطالب'],
            lesson: ['name', 'اسم الدرس'],
            watch_counte: 'عدد المشاهدات',
            watch_time: 'مده المشاهده'
        }).setData(lessonViews, {
            student: 'name',
            lesson: 'name'
        }).exportFile(` مشاهدات الدروس `)
    }

    const exportAll = () => {
        setpreparing(true)
        axios.get(`${config.basURL}center/views-student/${selectedCourse.course}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setpreparing(false)
            // console.log(res.data)
            Swal.fire({
                text: 'سيتم التحميل الان',
                icon: 'success'
            })
            let csv = new CSVMake;
            if(res.data.length == 0){
                Swal.fire({
                    text: ' لا يوجد مشاهدات في هذا الدرس  ' ,
                    icon: "warning"
                })
            }else {
                csv.setTableHeader({
                    student_name: 'أسم الطالب',
                    student_code: 'الكود',
                    student__parent_phone: 'رقم ولي الامر',
                    student__user__username: ' رقم هاتف الطالب ',
                    course_name: ' اسم الكورس ',
                    lesson_name: ' اسم الدرس ',
                    watch_counte: ' عدد المشاهدات ',
                    watch_time: ' مده المشاهده '
                }).setData(res.data).exportFile(`  بيانات مشاهدات الطلاب في كورس ${res.data[0].course_name}`)
            }

        }).catch(err => {
            setpreparing(false)
            console.log(err)
        })
    }

    const getCourseList = () => {
        axios.get(`${config.basURL}dashboard/course-overview`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,

            }
        }).then((res) => {
            setcourseList(res.data.courses)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (searchResults) {
            search()
        } else {
            getAllViews()
        }
    }, [userAuth, currentPage])

    useEffect(() => {
        getCourseList()
    }, [userAuth])

    return <>
        <div className="container-fluid lesson-views-page">
            <h3>
                المشاهدات : ( {viewsCount} )
            </h3>
            <div className="head">
                {
                    lessonViews && <button className='export-file my-4' onClick={exportCsv}>
                        <i className="fa-regular fa-file-excel"></i>
                        انشاء ملف Excel لهذه الصفحه
                    </button>
                }
                <div className='w-50 d-flex laign-items-center'>
                    <select name="course" id="" onChange={updateSelectedCourse}>
                        <option value={null}> اختار الكورس  </option>
                        {
                            courseList ? courseList.map(course => <option value={course.id} key={course.id}  > {course.name} </option>) : <option value={null}> جاري تحميل الكورسات </option>
                        }
                    </select>
                    {
                        selectedCourse.course !== null && <button className='export-file' onClick={exportAll}>
                            {
                                preparing ? <>
                                    <i className="fa-solid fa-circle-notch fa-spin"></i>
                                    جاري تجهيز الفايل
                                </> : <>
                                    <i className="fa-regular fa-file-excel"></i>
                                    جميع المشاهدات
                                </>
                            }
                        </button>
                    }
                </div>
            </div>

            <div className="leasson-views-search">
                <select name="search-by" id="" onChange={updateSearchBy}>
                    <option value="all"> البحث باستخدام... </option>
                    <option value="username">  رقم هاتف الطالب او الكود </option>
                    <option value="student_name"> اسم الطالب </option>
                </select>
                <form className='search-input' onSubmit={search}>
                    <input type="text" name={searchBy} onChange={updateSearchValue} required />
                    <button>
                        بحث
                    </button>
                </form>
            </div>
            <table className="content">
                <thead>
                    <tr>
                        <th> اسم الدرس </th>
                        <th> اسم الطالب </th>
                        <th> رقم الهاتف</th>
                        <th>  السنه الدراسيه </th>
                        <th> المكان </th>
                        <th> عدد المشاهدات </th>
                        <th> مده المشاهده </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lessonViews ? (lessonViews.length > 0 ? lessonViews.map(view => <LessonViewsRow key={view.id} data={view} refresh={getAllViews} />) : <tr>
                            <td colSpan={7} className='text-center'> لا توجد مشاهدات  </td>
                        </tr>) : <Placeholder />
                    }
                </tbody>
            </table>
            <div className="pagination-btn">
                <button onClick={() => setCurrentPage((prev) => prev - 1)} className={`prev ${currentPage > 1 ? '' : 'disable'}`} disabled={currentPage > 1 ? false : true}> السابق </button>
                <button className={`next ${nextPage ? '' : 'disable'}`} disabled={nextPage ? false : true} onClick={() => setCurrentPage((prev) => prev + 1)}> التالي </button>
            </div>
        </div>
    </>
}
